import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { Storage } from '@targx/libs/storage';
import { ApiService } from '@targx/services/api/api.service';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import {
  combineLatest,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { AuthUtils } from './auth.utils';

@Injectable()
export class AuthService {
  private _authenticated = false;
  private host: string = environment.api;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private api: ApiService,
    private _userService: UserService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    Storage.set(environment.KEY_LOCAL_STORAGE, token);
  }

  get accessToken(): string {
    return Storage.get(environment.KEY_LOCAL_STORAGE) ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<Data> {
    return of(null);
  }

  /**
   * Forgot password
   *
   * @param email
   */
  resetPassword(email: string): Observable<Data> {
    return of(null);
  }

  /**
   * Sign out
   */
  signOut(): Observable<boolean> {
    // Remove the access token from the local storage
    Storage.remove(environment.KEY_LOCAL_STORAGE);
    Storage.clear();

    // Set the authenticated flag to false
    this._authenticated = false;
    this._userService.user = null;
    this._userService.seller = null;

    // Return the observable
    return of(true);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return of(null);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    fullname: string;
    firstname: string;
    lastname: string;
    phone: string;
    email: string;
    password: string;
    agreements: boolean;
  }): Observable<any> {
    return this._httpClient
      .post(this.host + '/auth/local/register', {
        username: user.email,
        email: user.email,
        password: user.password,
        agreements: user.agreements,
      })
      .pipe(
        switchMap((response: any) => {
          // Remove the access token from the local storage
          Storage.remove(environment.KEY_LOCAL_STORAGE);
          Storage.clear();

          // Set the authenticated flag to false
          this._authenticated = false;
          this._userService.user = null;
          this._userService.seller = null;

          // Set the authenticated flag to true
          this._authenticated = false;

          return this.api.post('/sellers', {
            data: {
              name: user.fullname,
              phone: user.phone,
              users_permissions_users: [response.user.id],
              firstname: user.firstname,
              lastname: user.lastname,
            },
          });
        }),
      );
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<Data> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient.post(this.host + '/auth/local', credentials).pipe(
      switchMap((response: Data) => {
        // Store the access token in the local storage
        this.accessToken = response.jwt;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        //this._userService.user = response.user;

        // Return a new observable with the response
        return this._userService.get();
      }),
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    return combineLatest([this._userService.get()]).pipe(
      tap(([user]) => {
        console.log('🚀 ~ AuthService ~ tap ~ user:', user);
        // Store the user on the user service
        this._userService.user = user;

        // Set the authenticated flag to true
        this._authenticated = true;
      }),
    );
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();

    //return of(true);
  }
}
