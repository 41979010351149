<div class="flex flex-col bg-[#FAFAFA]  w-full ">
  <div class="w-full max-w-screen-lg px-4 pt-14 pb-16 md:px-14 mx-auto flex flex-col">

    <h3 class="w-full pb-3 widget-title">
      <span class="pb-3">{{ 'Contact Seller' | transloco }}</span>
    </h3>

    <div class="grid grid-cols-6 gap-6 mt-8">
      <div class="col-span-12 md:col-span-4 bg-white p-8">
        <div class="seller-contact-form-inner flex flex-col gap-4 items-start justify-self-start">
          <div class="font-semibold text-gray50-400">Preencha os seus dados e envie a sua mensagem</div>
          <!-- Sign Up form -->
          <!-- Alert -->
          <fuse-alert class="mt-8" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false" [type]="alert.type"
            [@shake]="alert.type === 'error'">
            {{alert.message}}
          </fuse-alert>
          <form class="mt-4" [formGroup]="signUpForm" #signUpNgForm="ngForm">


            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <!-- Name field -->
              <mat-form-field class="w-full">
                <mat-label>{{'Firstname' | transloco}}</mat-label>
                <input id="firstname" matInput [formControlName]="'firstname'">
                <mat-error *ngIf="signUpForm.get('firstname').hasError('required')">
                  {{'Firstname is required' | transloco}}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-full">
                <mat-label>{{'Lastname' | transloco}}</mat-label>
                <input id="lastname" matInput [formControlName]="'lastname'">
                <mat-error *ngIf="signUpForm.get('lastname').hasError('required')">
                  {{'Lastname is required' | transloco}}
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Email field -->
            <mat-form-field class="w-full">
              <mat-label>{{'Email address' | transloco}}</mat-label>
              <input id="email" matInput [formControlName]="'email'">
              <mat-error *ngIf="signUpForm.get('email').hasError('required')">
                {{'Email address is required' | transloco}}
              </mat-error>
              <mat-error *ngIf="signUpForm.get('email').hasError('email')">
                {{'Please enter a valid email address' | transloco}}
              </mat-error>
            </mat-form-field>

            <!-- Email field -->
            <mat-form-field class="w-full">
              <mat-label>{{'Phone' | transloco}}</mat-label>
              <input id="phone" matInput [formControlName]="'phone'">
              <mat-error *ngIf="signUpForm.get('phone').hasError('required')">
                {{'Field is required' | transloco}}
              </mat-error>
            </mat-form-field>



            <!-- Password field -->
            <mat-form-field class="w-full">
              <mat-label>{{'Message' | transloco}}</mat-label>
              <textarea id="message" matInput [formControlName]="'message'" row="6"></textarea>
              <mat-error *ngIf="signUpForm.get('message').hasError('required')">
                {{'Field is required' | transloco}}
              </mat-error>
            </mat-form-field>

            <!-- ToS and PP -->
            <div class="inline-flex items-end w-full mt-1.5">
              <mat-checkbox class="-ml-2 border-white" [color]="'primary'" [formControlName]="'agreements'">
                <span>{{'I agree with' | transloco}}</span>
                <a class="ml-1 text-primary-500 hover:underline" [routerLink]="['./']">
                  {{'Terms' | transloco}}
                </a>
                <span>{{'and' | transloco}}</span>
                <a class="ml-1 text-primary-500 hover:underline" [routerLink]="['./']">
                  {{'Privacy Policy' | transloco}}
                </a>
              </mat-checkbox>
            </div>

            <!-- Submit button -->
            <button class="fuse-mat-button-large w-full mt-6 rounded-none" mat-raised-button [color]="'primary'"
              [disabled]="signUpForm.disabled || signUpForm.invalid" (click)="onSubmit()">
              <span *ngIf="!signUpForm.disabled">
                {{'Send message' | transloco}}
              </span>
              <mat-progress-spinner *ngIf="signUpForm.disabled" [diameter]="24"
                [mode]="'indeterminate'"></mat-progress-spinner>
            </button>

          </form>
        </div>
      </div>
      <div class="col-span-12 md:col-span-2 ">
        <div class="seller-info-inner flex flex-col gap-4 bg-white p-8 items-start justify-self-start">

          <div class="seller-avatar flex w-18 h-18 bg-gray-100 rounded-full"></div>

          <div class="text-black-motorbest-600 text-xl leading-3xl font-light">
            {{product.seller?.data?.attributes?.name}}
          </div>

          <div class="flex flex-col mt-4">
            <div class="text-gray40-500 uppercase text-sm leading-base">{{'Address' | transloco}}</div>
            <div class="text-black-motorbest-600 text-base leading-base">{{product.seller?.data?.attributes?.address}}
            </div>
            <div class="text-black-motorbest-600 text-base leading-base mt-2">
              {{product.seller?.data?.attributes?.country}}
            </div>

          </div>

          <div class="flex flex-col mt-4">
            <button mat-button class="flex gap-2 items-center rounded-none -ml-4" [color]="'primary'" *ngIf="!showPhone"
              (click)="togglePhone()">
              <mat-icon *ngIf="!loading" svgIcon="heroicons_outline:phone"></mat-icon>
              <span *ngIf="!loading">{{'Show Phone' | transloco}}</span>
              <mat-progress-spinner *ngIf="loading" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </button>
            <ng-container *ngIf="showPhone">

              <div class="text-gray40-500 uppercase text-sm leading-base mb-1">{{'Phone' | transloco}}</div>
              <div class="text-black-motorbest-600 text-base leading-base">{{product.seller?.data?.attributes?.phone}}

              </div>
            </ng-container>
          </div>

          <div class="flex flex-col mt-4" *ngIf="false">
            <div class="text-gray40-500 uppercase text-sm leading-base">{{'Website' | transloco}}</div>
            <div class="text-black-motorbest-600 text-base leading-base">{{product.seller?.data?.attributes?.phone}}
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</div>