import { Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { ApiService } from '@targx/services/api/api.service';
import { User } from 'app/core/user/user.types';
import { Observable, of, ReplaySubject, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  private _seller: ReplaySubject<Data> = new ReplaySubject<Data>(1);
  public _currentUser: User;
  public _currentSeller: Data;

  /**
   * Constructor
   */
  constructor(private api: ApiService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------
  get user$(): Observable<User> {
    return this._user.asObservable();
  }
  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._currentUser = value;
    this._user.next(value);
  }

  get user(): User {
    return this._currentUser;
  }

  get seller$(): Observable<Data> {
    return this._user.asObservable();
  }

  set seller(value: Data) {
    // Store the value
    this._currentSeller = value;
    this._seller.next(value);
  }

  get seller(): Data {
    return this._currentSeller;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */

  get(): Observable<User> {
    return this.api.get<User>('/users/me').pipe(
      switchMap((response) => {
        return this.api
          .get<Data>('/sellers?filters[users_permissions_users]=' + response.id)
          .pipe(
            switchMap((seller) => {
              this.seller = seller.data[0]?.attributes;
              this.seller.id = seller.data[0]?.id;
              this.user = response;
              return of(response);
            }),
          );
      }),
    );
  }

  /**
   * Update the user
   *
   * @param user
   */
  update(user: User): Observable<any> {
    return this.api.post<any>('/users/me').pipe(
      switchMap((response) => {
        this.user = response;
        return of(response);
      }),
    );
  }

  hasRole(role: string | string[]): boolean {
    return true;
  }
}
