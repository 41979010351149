<!-- Button -->
<ng-container *ngIf="!user?.email">

  <button (click)="openSignInDialog()" class="flex items-center gap-2 text-sm">
    {{'SignIn | SignUp' | transloco}}
    <mat-icon svgIcon="feather:user" class="icon-size-5 text-gray50-400 mr-4 cursor-pointer"></mat-icon>
  </button>
</ng-container>

<ng-container *ngIf="user?.email">
  <button [matMenuTriggerFor]="userActions" class="flex items-center gap-2 text-sm">
    <span class="relative flex items-end  gap-2">
      <div class="flex flex-col justify-start items-start truncate max-w-48">
        {{'Hello' | transloco}}, {{user?.seller?.firstname ?? user?.email}}
      </div>
      <mat-icon *ngIf="!showAvatar || !user.avatar" [svgIcon]="'feather:user'"
        class="icon-size-5 text-gray50-400 mr-4 mb-1 cursor-pointer"></mat-icon>
      <span class="absolute right-4 bottom-0 w-2 h-2 rounded-full" [ngClass]="{
        'mr-px mb-px': !showAvatar || !user.avatar,
        'bg-green-500': true
      }"></span>
    </span>
  </button>

  <mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item [routerLink]="[activeLang, 'private', 'settings','profile']">
      <span class="flex flex-col leading-none">
        <span>{{'Signed in as' | transloco}}</span>
        <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
      </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item [routerLink]="[activeLang, 'private', 'dashboard']">
      <mat-icon [svgIcon]="'feather:grid'"></mat-icon>
      <span>{{'Dashboard' | transloco}}</span>
    </button>

    <button mat-menu-item [routerLink]="[activeLang, 'private', 'my-cars']">
      <mat-icon [svgIcon]="'mat_outline:directions_car_filled'"></mat-icon>
      <span>{{'My Cars' | transloco}}</span>
    </button>

    <button mat-menu-item [routerLink]="[activeLang, 'private', 'messages']">
      <mat-icon [svgIcon]="'feather:message-square'"></mat-icon>
      <span>{{'Messages' | transloco}}</span>
    </button>

    <button mat-menu-item [routerLink]="[activeLang, 'private', 'wishlist']">
      <mat-icon [svgIcon]="'feather:heart'"></mat-icon>
      <span>{{'Wishlist' | transloco}}</span>
    </button>

    <button mat-menu-item [routerLink]="[activeLang, 'private', 'settings']">
      <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
      <span>{{'Settings' | transloco}}</span>
    </button>

    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
      <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
      <span>{{'Sign out' | transloco}}</span>
    </button>
  </mat-menu>
</ng-container>