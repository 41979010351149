/* eslint-disable @typescript-eslint/naming-convention */
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private host: string = environment.api;
  private solr: string = environment.solr;

  constructor(private http: HttpClient) {}

  public get<T>(
    url: string,
    data?: any,
    urlappend?: string,
    serialize = true,
  ): Observable<T> {
    data = data || {};
    const params: HttpParams = serialize ? this.serialize(data) : data;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .get<T>(
        this.host + url + (typeof urlappend !== 'undefined' ? urlappend : ''),
        { headers, params: data },
      )
      .pipe(catchError(this.handleError));
  }
  public getSolr<T>(
    url: string,
    data?: any,
    urlappend?: string,
    serialize = true,
  ): Observable<T> {
    data = data || {};
    const params: HttpParams = serialize ? this.serialize(data) : data;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .get<T>(
        this.solr + url + (typeof urlappend !== 'undefined' ? urlappend : ''),
        { headers, params: data },
      )
      .pipe(catchError(this.handleError));
  }

  public out<T>(url: string, data?: any, urlappend?: string): Observable<T> {
    data = data || {};
    const params: HttpParams = this.serialize(data);
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .get<T>(url + (typeof urlappend !== 'undefined' ? urlappend : ''), {
        headers,
        params: data,
      })
      .pipe(catchError(this.handleError));
  }

  public post<T>(url: string, data?: any, urlappend?: string) {
    data = data || {};
    const params: HttpParams = this.serialize(data);
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<T>(
      this.host + url + (typeof urlappend !== 'undefined' ? urlappend : ''),
      data,
      {
        headers,
        params,
      },
    );
    // .pipe(catchError(this.handleError));
  }

  public postOut<T>(
    url: string,
    data?: any,
    urlappend?: string,
  ): Observable<T> {
    data = data || {};
    const params: HttpParams = this.serialize(data);
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .post<T>(
        url + (typeof urlappend !== 'undefined' ? urlappend : ''),
        data,
        {
          headers,
          params,
        },
      )
      .pipe(catchError(this.handleError));
  }

  public put<T>(url: string, data?: any, urlappend?: string): Observable<T> {
    data = data || {};
    const params: HttpParams = this.serialize(data);
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put<T>(
      this.host + url + (typeof urlappend !== 'undefined' ? urlappend : ''),
      data,
      {
        headers,
        params,
      },
    );
    // .pipe(catchError(this.handleError));
  }

  /**
   *
   */
  public raw<T>(
    url: string,
    type: string,
    data?: any,
    urlappend?: string,
  ): Observable<T> {
    data = data || {};

    if (data && type === 'GET') {
      return this.out<T>(this.host + url, data, urlappend);
    }

    const formData = new FormData();
    Object.keys(data).forEach((k: string) => {
      if (data[k] instanceof Array) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < data[k].length; i++) {
          formData.append(`${k}`, data[k][i]);
        }
      } else {
        formData.append(k, data[k]);
      }
    });

    return this.http.post<T>(
      this.host + url + (typeof urlappend !== 'undefined' ? urlappend : ''),
      formData,
    );
  }

  /**
   *
   */
  public rawGeo<T>(
    url: string,
    type: string,
    data?: any,
    urlappend?: string,
  ): Observable<T> {
    data = data || {};

    if (data && type === 'GET') {
      return this.out<T>(url, data, urlappend);
    }

    const formData = new FormData();
    Object.keys(data).forEach((k: string) => {
      if (data[k] instanceof Array) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < data[k].length; i++) {
          formData.append(`${k}[]`, data[k][i]);
        }
      } else {
        formData.append(k, data[k]);
      }
    });

    return this.http.post<T>(
      url + (typeof urlappend !== 'undefined' ? urlappend : ''),
      formData,
    );
  }

  private serialize(data?: any) {
    const body = new HttpParams();
    Object.keys(data).forEach((key) => {
      body.set(key, data[key]);
    });

    return body;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`,
      );
    }
    // return an observable with a user-facing error message
    return throwError(JSON.stringify(error));
  }
}
