import { CommonModule, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoCoreModule } from '@core/transloco/transloco.module';
import { FuseAlertModule } from '@fuse/components/alert';
import { FuseScrollResetModule } from '@fuse/directives/scroll-reset';
import { FuseScrollbarModule } from '@fuse/directives/scrollbar';

import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { SwiperModule } from '@targx/directives/swiper/swiper.module';
import { CardAdsComponent } from './components/card-ads/card-ads.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';

import localePt from '@angular/common/locales/pt';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { ImageModule } from '@targx/components/image/image.module';
import { PaginationModule } from '@targx/components/pagination/pagination.module';
import { FiltersComponent } from './components/filters/filters.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { SellerContactFormComponent } from './components/seller-contact-form/seller-contact-form.component';
import { widgets } from './widgets';

registerLocaleData(localePt);

const loadModules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  TranslocoCoreModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatLuxonDateModule,
  MatMenuModule,
  MatProgressBarModule,
  MatIconModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatTableModule,
  MatTooltipModule,
  MatSlideToggleModule,
  FuseScrollbarModule,
  FuseScrollResetModule,
  FuseAlertModule,
  MatPaginatorModule,
  MatButtonToggleModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatExpansionModule,
  MatDialogModule,
  SwiperModule,
  ImageModule,
  MatRadioModule,
  YouTubePlayerModule,
  MatSnackBarModule,
  PaginationModule,
  MatProgressSpinnerModule,
];

const components = [
  NewsletterComponent,
  CardAdsComponent,
  ProductCardComponent,
  FiltersComponent,
  SellerContactFormComponent,
];

@NgModule({
  imports: [...loadModules],
  exports: [...loadModules, ...widgets, ...components],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-PT',
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'D',
        },
        display: {
          dateInput: 'DDD',
          monthYearLabel: 'LLL yyyy',
          dateA11yLabel: 'DD',
          monthYearA11yLabel: 'LLLL yyyy',
        },
      },
    },
  ],
  declarations: [...widgets, ...components],
})
export class SharedModule {}
