<Image [src]="product.images[0].url" [alt]="product.images[0].alt" [href]="link" [full]="false"
  class=" max-h-50 md:max-h-60 h-50 mb-4">
</Image>
<a [routerLink]="link">
  <h4
    class="flex justify-center text-left text-black-motorbest-500 font-normal leading-3xl mb-2 h-12 overflow-hidden text-2xl"
    [title]="product.name_s">
    {{ product?.year_i }}
    {{ product.name_s }}
  </h4>

  <div class="text-md leading-2xl font-semibold text-center mb-2 text-gray50-500">
    <ng-container *ngIf="!product.price_f">
      {{ "Sob Consulta" | transloco }}
    </ng-container>
    <ng-container *ngIf="product.price_f">
      {{ product.price_f | currency: "EUR" }}
    </ng-container>
  </div>

  <div class="text-sm leading-base font-light text-center flex justify-center items-center">
    <img src="assets/images/flags/{{ product.country_s}}.svg" alt="Location" class="w-4 h-3 mr-2" />
    {{ product.city_s }}, {{ product.country_s}}
  </div>
</a>